import { useEffect, useState } from "react";
import { useParams } from "react-router";
import green from "../assets/green.png";
import car from "../assets/car.webp";
import greenpaper from "../assets/greenPaper.png";

const Receive = () => {
    const [present, setPresent] = useState({
        imageURI: car,
        imageWidth: 100,
        imageHeight: 100,
        isOpened: false,
        attributes: {
            text: "This is a present!",
            texture: "bluePaper.png",
        },
    });

    var mouseDown = false;
    var lastX = 0;
    var lastY = 0;

    var callbackLock = false;
    var presentOpened = false;

    const touchesRequired = 5;
    const thresholdRemove = 0.7;

    const [texture, setTexture] = useState(greenpaper);
    const [particleTexture, setParticleTexture] = useState(greenpaper);
    const [segementedImage, setSegmentedImage] = useState(null);
    const [segmentCount, setSegmentCount] = useState(50);
    const [showDialog, setShowDialog] = useState(false);
    const [removedPixelThreshold, setRemovedPixelThreshold] = useState(1000000);

    const params = useParams();

    //Make body only 100vh and 100vw and non-scrollable
    document.body.style.height = "100vh";
    document.body.style.width = "100vw";
    document.body.style.overflow = "hidden";

    var particles = [];
    var flyingParts = [];
    //Init the segments touched with N*0
    var segmentsTouched = Array(segmentCount).fill(0);
    var removedPixels = 0;
    var removedSegments = [];
    //Add partikel
    function addParticle(x, y) {
        //Get x coord and y coord of wrapper
        let wrapper = document.getElementById("wrapper");
        var x_coord = wrapper.getBoundingClientRect().x;
        var y_coord = wrapper.getBoundingClientRect().y;
        const particle = document.createElement("div");
        particle.style.backgroundImage = `url(${particleTexture})`;
        particle.style.position = "absolute";
        particle.style.zIndex = 1;
        particle.style.width = "15px";
        particle.style.height = "15px";
        particle.style.left = `${x - x_coord}px`;
        particle.style.top = `${y - y_coord}px`;
        particle.className = "particle";
        wrapper.appendChild(particle);
        particles.push({
            element: particle,
            x: x - x_coord,
            y: y - y_coord,
            xSpeed: Math.random() * 10 - 5,
            ySpeed: Math.random() * 10 - 5,
            life: 100,
        });
    }

    function loadCanvas(texture, width, height) {
        const canvas = document.createElement("canvas");
        canvas.id = "canvas";
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        if (present.isOpened) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        } else {
            ctx.fillStyle = "#000000";
            var img = new Image();
            img.src = texture;
            img.onload = () => {
                for (let x = 0; x < width; x += img.width) {
                    for (let y = 0; y < height; y += img.height) {
                        ctx.drawImage(img, x, y);
                    }
                }
            };
        }
        let imageWrapper = document.getElementById("image-wrapper");
        imageWrapper.style.height = height + "px";
        let imageCover = document.getElementById("image-cover");
        if (imageCover.children.length === 0) {
            imageCover.appendChild(canvas);
        } else {
            imageCover.replaceChild(canvas, imageCover.children[0]);
        }
        let presentImage = document.getElementById("present-image");
        presentImage.src = present.imageURI;
    }

    async function init() {
        if (present.imageURI === car) {
            return;
        }
        //set image-wrapper to be 2/3 of the screen and height equals width
        let imageWrapper = document.getElementById("image-wrapper");
        imageWrapper.style.width = "83.3333333%";
        imageWrapper.style.height = imageWrapper.offsetWidth + "px";
        //Set height to heigh of image wrapper or width of image wrapper depending on the aspect ratio
        const aspectRatio = present.imageHeight / present.imageWidth;
        var height = imageWrapper.offsetHeight;
        var width = height / aspectRatio;
        if (width > imageWrapper.offsetWidth) {
            width = imageWrapper.offsetWidth;
            height = width * aspectRatio;
        }
        //Round up
        width = Math.ceil(width);
        height = Math.ceil(height);

        //Set the removed pixel threshold
        setRemovedPixelThreshold(width * height * thresholdRemove);
        console.log(removedPixelThreshold);

        //Load the image into the canvas
        loadCanvas(texture, width, height);

        //Axios get to https://present-not-present.com/api/generatesegments?strategy=fuzzyLines&width=100&height=100&lines=50&fuzziness=100
        await fetch(
            `https://present-not-present.com/api/generatesegments?strategy=fuzzyLines&width=${height}&height=${width}&lines=100&fuzziness=100`
        )
            .then((response) => {
                response.json().then((data) => {
                    //result.segmentMap
                    setSegmentedImage(data.result.segmentMap);
                    //Count unique segments
                    var uniqueSegmentCount = 0;
                    let segmentMap = data.result.segmentMap;
                    let uniqueSegments = [];
                    //Iterate over the segmentMap and count unique segments
                    for (let x = 0; x < segmentMap.length; x++) {
                        for (let y = 0; y < segmentMap[0].length; y++) {
                            if (!uniqueSegments.includes(segmentMap[x][y])) {
                                uniqueSegments.push(segmentMap[x][y]);
                                uniqueSegmentCount += 1;
                            }
                        }
                    }
                    //Console log the unique segments
                    //Log dimensions of map
                    setSegmentCount(uniqueSegmentCount);
                    segmentsTouched = Array(uniqueSegmentCount).fill(0);
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function handleDrag(x, y) {
        //Add particle with chance of 1/10
        if (Math.random() < 1) {
            addParticle(x, y);
        }
        let wrapper = document.getElementById("wrapper");
        let imageWrapper = document.getElementById("image-wrapper");
        var x_diff = x - imageWrapper.getBoundingClientRect().x;
        var y_diff = y - imageWrapper.getBoundingClientRect().y;

        //Log all coordinates
        if (x_diff < 0 || y_diff < 0) {
            return;
        }
        if (
            x_diff >= imageWrapper.offsetWidth ||
            y_diff >= imageWrapper.offsetHeight
        ) {
            return;
        }

        //Check if x and y are within the bounds of the canvas element with id "canvas"
        const canvas = document.getElementById("canvas");
        const canvas_x = canvas.getBoundingClientRect().x;
        const canvas_y = canvas.getBoundingClientRect().y;
        if (x < canvas_x || x > canvas_x + canvas.width) {
            return;
        }
        if (y < canvas_y || y > canvas_y + canvas.height) {
            return;
        }

        x_diff = x_diff - (canvas_x - imageWrapper.getBoundingClientRect().x);
        y_diff = y_diff - (canvas_y - imageWrapper.getBoundingClientRect().y);

        //Determine the segment touched
        let segment_number =
            segementedImage[Math.floor(x_diff)][Math.floor(y_diff)];
        segmentsTouched[segment_number] += 1;
        if (
            segmentsTouched[segment_number] > touchesRequired &&
            !removedSegments.includes(segment_number)
        ) {
            removedSegments.push(segment_number);
            //Get ctx
            const canvas = document.getElementById("canvas");
            const ctx = canvas.getContext("2d");

            //Iterate over all pixels and set the alpha to 0 for the segment
            for (let x = 0; x < segementedImage.length; x++) {
                for (let y = 0; y < segementedImage[0].length; y++) {
                    if (segementedImage[x][y] === segment_number) {
                        removedPixels += 1;
                        ctx.clearRect(x, y, 1, 1);
                    }
                }
            }
        }
        let touchedSegments = 0;
        segmentsTouched.forEach((segment) => {
            if (segment > touchesRequired) {
                touchedSegments += 1;
            }
        });
        if (!callbackLock && removedPixels > removedPixelThreshold) {
            callbackLock = true;
            //Reveal all pixels
            let canvas = document.getElementById("canvas");
            let ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            setShowDialog(true);
            //Remove all event listeners
            wrapper.removeEventListener("mousedown", () => {});
            wrapper.removeEventListener("mousemove", () => {});
            wrapper.removeEventListener("mouseup", () => {});
            wrapper.removeEventListener("touchmove", () => {});
            //Send request to backend to set present to opened
            fetch(
                `https://present-not-present.com/api/openedpresent?uid=${params.presentid}`
            ).then((response) => {
                setPresent({ ...present, isOpened: true });
                presentOpened = true;
            });
        }
    }

    useEffect(() => {
        if (segementedImage) {
            let wrapper = document.getElementById("wrapper");
            //Also add listeners for click and drag events
            wrapper.addEventListener("mousedown", (event) => {
                mouseDown = true;
                lastX = event.clientX;
                lastY = event.clientY;
            });
            wrapper.addEventListener("mousemove", (event) => {
                if (mouseDown) {
                    //Min distance between points
                    if (
                        Math.abs(event.clientX - lastX) < 5 &&
                        Math.abs(event.clientY - lastY) < 5
                    ) {
                        return;
                    }
                    handleDrag(event.clientX, event.clientY);
                }
            });
            wrapper.addEventListener("mouseup", (event) => {
                mouseDown = false;
            });

            wrapper.addEventListener("touchmove", (event) => {
                handleDrag(event.touches[0].clientX, event.touches[0].clientY);
            });
            //Update particles
            setInterval(() => {
                particles.forEach((particle) => {
                    particle.x += particle.xSpeed;
                    particle.y += particle.ySpeed;
                    particle.life -= 1;
                    particle.element.style.left = `${particle.x}px`;
                    particle.element.style.top = `${particle.y}px`;
                    particle.element.style.opacity = particle.life / 100;
                    if (particle.life <= 0) {
                        wrapper.removeChild(particle.element);
                    }
                });

                flyingParts.forEach((particle) => {
                    particle.x += particle.xSpeed;
                    particle.y += particle.ySpeed;
                    particle.ySpeed += 0.5;
                    particle.life -= 1;
                    particle.element.style.left = `${particle.x}px`;
                    particle.element.style.top = `${particle.y}px`;
                    particle.element.style.opacity = particle.life / 100;
                    if (particle.life <= 0) {
                        wrapper.removeChild(particle.element);
                    }
                });
                flyingParts = flyingParts.filter(
                    (particle) => particle.life > 0
                );
                particles = particles.filter((particle) => particle.life > 0);
            }, 1000 / 60);
        }
    }, [segementedImage]);

    useEffect(() => {
        //Fetch present from backend
        fetch(
            `https://present-not-present.com/api/getpresent?uid=${params.presentid}`
        ).then((response) => {
            response.json().then((data) => {
                const texture_to_use = data.result.attributes.texture;
                fetch(
                    `https://present-not-present.com/api/getwrappings?filter=${texture_to_use}&scale=128`
                )
                    .then((response) => {
                        //Fetch same texture from backend with scale 8x8
                        fetch(
                            `https://present-not-present.com/api/getwrappings?filter=${texture_to_use}&scale=8`
                        ).then((response) => {
                            response.json().then((data) => {
                                console.log(data);
                                //Load the texture from the response data result/wrappings/${present.attributes.texture}/imageB64 and store it in texture
                                var b64 = data.result.wrappings[0].imageB64;
                                //Convert b64 to image
                                let src = "data:image/png;base64," + b64;
                                setParticleTexture(src);
                            });
                        });

                        response.json().then((data) => {
                            console.log(data);
                            //Load the texture from the response data result/wrappings/${present.attributes.texture}/imageB64 and store it in texture
                            var b64 = data.result.wrappings[0].imageB64;
                            //Convert b64 to image
                            let src = "data:image/png;base64," + b64;
                            setTexture(src);
                        });
                    })
                    .then(() => {
                        //Set present to the fetched present
                        setPresent(data.result);
                        presentOpened = data.result.isOpened;
                    });
            });
        });
    }, []);

    useEffect(() => {
        init();
    }, [texture]);

    return (
        <div
            id="wrapper"
            className="relative h-full w-full text-back overflow-hidden"
        >
            {/* Add a dialog that shows the message */}
            <div
                className={`${
                    showDialog ? "block" : "hidden"
                } fixed z-10 inset-0 overflow-y-auto`}
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
            >
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                        aria-hidden="true"
                    ></div>
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div
                        className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-title"
                    >
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <h3
                                        className="text-lg leading-6 font-medium text-gray-900"
                                        id="modal-title"
                                    >
                                        Congratulations! You have opened your
                                        present! 🎉 You also received a message:
                                    </h3>
                                    <div className="mt-4">
                                        <p className="text-md text-black">
                                            {present.attributes.text}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => setShowDialog(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-12 space-y-8 flex flex-col justify-start items-center h-full">
                {present.isOpened ? (
                    <p className="text-center text-xl font-semibold">
                        You have opened your present!
                    </p>
                ) : (
                    <p className="text-center text-xl font-semibold">
                        Open your present! Rip off the wrapping!
                        <br /> (with touch motions or mouse drags)
                    </p>
                )}
                <div id="image-wrapper" className="relative w-5/6 h-48">
                    <div
                        id="image-cover"
                        className="absolute flex flex-row justify-center items-center top-0 left-0 w-full h-full"
                    ></div>

                    <img
                        id="present-image"
                        alt="Present"
                        className="w-full h-full object-contain"
                        style={{ userSelect: "none" }}
                    />
                </div>
                {/* Button to open dialog that shows text */}
                <div className={present.isOpened ? "block" : "hidden"}>
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={() => setShowDialog(true)}
                    >
                        Show message again
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Receive;
