import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Receive from "./pages/Receive";
import Present from "./pages/Present";
import presentsbackground from "./assets/presentsbackground.png";
import logo from "./assets/logo-transparent.png";

const AppRouter = () => {
    return (
        <div className="w-full h-full overflow-hidden">
            <div className="header w-full h-36">
                {/* Add bold text that has the background texture presentsbackground and shows the text HOME */}
                <div className="w-full h-full flex items-center justify-center">
                    <img
                        src={logo}
                        alt="logo"
                        className="h-full w-full object-contain hover:cursor-pointer"
                        onClick={() => window.location.replace("/")}
                        style={{ userSelect: "none" }}
                    />
                </div>
            </div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/:presentid" element={<Receive />} />
                    <Route path="/present" element={<Present />} />
                    <Route path="*" element={<Home />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default AppRouter;
