import buttonbackground from "../assets/presentsbackground.png";

const Home = () => {
    return (
        <div className="flex flex-col justify-center w-full h-full items-center">
            {/* Make background opaque */}
            <button className="relative font-bold text-xl w-1/2 rounded-full shadow-2xl h-20 ">
                {/* */}
                <div
                    className="sm:text-xl md:text-2xl font-bold  w-full h-full flex items-center rounded-full justify-center mix-blend-mode-screen text-black bg-slate-200
                bg-opacity-50 hover:bg-opacity-100 hover:cursor-pointer hover:shadow-2xl hover:rounded-full hover:bg-cover hover:bg-center hover:bg-no-repeat"
                    onClick={() => window.location.replace("/present")}
                >
                    Make a present
                </div>
            </button>
        </div>
    );
};

export default Home;
