import axios from "axios";
import React, { useState } from "react";
import presentsbackground from "../assets/presentsbackground.png";
const Create = () => {
    const [textures, setTextures] = useState([]);

    const [selectedImage, setImage] = useState(null);
    const [selectedImageHeight, setImageHeight] = useState(0);
    const [selectedImageWidth, setImageWidth] = useState(0);
    const [selectedTexture, setTexture] = useState(null);
    const [selectedText, setText] = useState("");
    const [isUploading, setUploading] = useState(false);

    const [generatedLink, setLink] = useState("");
    const [presentWrapped, setPresentWrapped] = useState(false);

    useState(() => {
        fetch(
            "https://present-not-present.com/api/getwrappings?scale=128",
            {}
        ).then((response) => {
            response.json().then((data) => {
                const wrappings = data.result.wrappings;
                setTextures(wrappings);
            });
        });
    }, []);

    function uploadPresent() {
        //Append debugging message to the body
        try {
            var imageString = selectedImage.split(",")[1];
            //Compress imageString
            const image = new Image();
            image.src = selectedImage;
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            const context = canvas.getContext("2d");
            context.drawImage(image, 0, 0);
            imageString = canvas.toDataURL("image/jpeg", 0.5).split(",")[1];

            const present = {
                imageBase64: imageString,
                attributes: {
                    text: selectedText,
                    texture: selectedTexture.displayName,
                },
            };
            //Make simple request
            axios
                .post("https://present-not-present.com/api/createpresent", {
                    imageBase64: imageString,
                    attributes: {
                        text: selectedText,
                        texture: selectedTexture.displayName,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        setLink(response.data.result.presentURI);
                    }
                });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="h-full text-center">
            <div className="relative flex flex-col h-full w-full justify-start space-y-4">
                {/* Empty image placeholder with upload button in the middle */}
                <input
                    className="hidden"
                    type="file"
                    accept="image/*"
                    id="image-upload"
                    onChange={(event) => {
                        //Check if a file is selected
                        if (!event.target.files.length) {
                            return;
                        }
                        const file = event.target.files[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            setImage(reader.result);
                            //Append text node to body
                            let text = document.createTextNode(reader.result);
                            document.body.appendChild(text);
                        };
                        //Add reader result to the body for debugging

                        reader.readAsDataURL(file);
                        //Get height and width of the image
                        const image = new Image();
                        image.src = URL.createObjectURL(file);
                        image.onload = () => {
                            setImageHeight(image.height);
                            setImageWidth(image.width);
                        };
                    }}
                />
                {selectedImage ? (
                    <div className="mx-auto w-2/3 h-36 md:h-48 relative flex flex-row justify-items-center items-center">
                        {
                            //Absolute div of same size as parent to center the later created canvas
                            <div
                                className="absolute w-full h-full top-0 left-0 flex flex-row justify-center items-center"
                                id="canvas-wrapper"
                            ></div>
                        }

                        <img
                            id="selected-image"
                            src={selectedImage}
                            alt="Selected"
                            className="w-full mx-auto h-36 md:h-48 rounded-3xl object-contain bg-transparent"
                            onClick={() => {
                                if (presentWrapped) {
                                    return;
                                }
                                document.getElementById("image-upload").click();
                            }}
                        />
                    </div>
                ) : (
                    <div
                        className="relative w-2/3 mx-auto h-36 md:h-48 border-2 border-gray-400 rounded-3xl flex justify-center items-center shadow-2xl"
                        onClick={() => {
                            document.getElementById("image-upload").click();
                        }}
                    >
                        {/* Background image assets/presentsbackground.webp */}
                        <img
                            src={presentsbackground}
                            alt=""
                            className="rounded-3xl w-full h-full object-cover opacity-40"
                        />

                        {/* Upload button in the middle of the image*/}
                        <div className="absolute flex flex-col items-center justify-center rounded-full h-48">
                            <div className="text-black font-semibold text-xl text-center">
                                <p className="text-lg">Step 1</p>
                                <p className="text-black text-2xl font-bold">
                                    Click here
                                </p>
                                <p className="text-lg">to upload an image</p>
                                <p className="text-lg">of your present</p>
                            </div>
                        </div>
                    </div>
                )}

                <div
                    className={`${
                        selectedImage && !presentWrapped ? "block" : "hidden"
                    } flex flex-col w-full space-y-4`}
                >
                    <div>
                        {/* Step 2: Choose a texture */}
                        <div className="text-lg md:text-2xl text-black font-bold">
                            Step 2: Pick one of the presented wrappings.
                        </div>
                    </div>
                    <div
                        className={`ml-4 flex w-full overflow-scroll [&>div]:flex-shrink-0 space-x-6`}
                    >
                        {textures.map((texture) => {
                            return (
                                <div
                                    key={texture.id}
                                    onClick={() => {
                                        setTexture(texture);
                                    }}
                                    className={
                                        selectedTexture === texture
                                            ? "border-2 border-blue-500"
                                            : ""
                                    }
                                >
                                    <img
                                        className="w-24 h-24"
                                        key={texture.id}
                                        src={`data:image/png;base64,${texture.imageB64}`}
                                        alt={texture.displayName}
                                    />
                                    <p>{texture.displayName}</p>
                                </div>
                            );
                        })}
                    </div>
                    {/* Button here that says "Wrap the present!" */}
                    <button
                        className={
                            selectedImage && selectedTexture
                                ? "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-1/2 mx-auto"
                                : "bg-gray-500 text-white font-bold py-2 px-4 rounded w-1/2 mx-auto"
                        }
                        onClick={() => {
                            setPresentWrapped(true);
                            // Add canvas and draw repeated texture over the image
                            const image =
                                document.getElementById("selected-image");
                            const canvas = document.createElement("canvas");
                            //Make canvas absolute to the image
                            //Also check the aspect ratio of the image source

                            const aspectRatio =
                                selectedImageWidth / selectedImageHeight;
                            console.log(aspectRatio);
                            //Use aspect ratio to set new width, because height is fixed
                            canvas.height = image.height;
                            canvas.width = image.height * aspectRatio;
                            //Center the canvas horizontally global scope. Add 1/6 screen width to the left
                            canvas.style.left = `${
                                (image.width - canvas.width) / 2
                            }px`;

                            const context = canvas.getContext("2d");
                            const texture = new Image();
                            texture.src = `data:image/png;base64,${selectedTexture.imageB64}`;
                            texture.onload = () => {
                                const pattern = context.createPattern(
                                    texture,
                                    "repeat"
                                );
                                context.fillStyle = pattern;
                                context.fillRect(
                                    0,
                                    0,
                                    canvas.width,
                                    canvas.height
                                );
                            };
                            //Append canvas to the canvas wrapper
                            document
                                .getElementById("canvas-wrapper")
                                .appendChild(canvas);
                        }}
                    >
                        Wrap the present!
                    </button>
                </div>

                {
                    <div
                        className={`${
                            presentWrapped && !generatedLink
                                ? "block"
                                : "hidden"
                        } w-full flex flex-col items-center justify-start space-y-4`}
                    >
                        <p className="text-xl md:text-2xl w-4/5 text-black font-bold">
                            Step 3: Add a personal message.
                        </p>
                        <textarea
                            className="border-2 w-4/5 h-24"
                            value={selectedText}
                            placeholder="Add a personal message for the presentee here."
                            rows={10}
                            onChange={(event) => {
                                setText(event.target.value);
                            }}
                        />
                    </div>
                }
                {
                    /* Submit button */
                    <div
                        className={
                            selectedImage &&
                            presentWrapped &&
                            selectedText !== "" &&
                            generatedLink === ""
                                ? "block"
                                : "hidden"
                        }
                    >
                        {/* Loading Animation */}

                        <button
                            className={
                                selectedImage && selectedTexture
                                    ? "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    : "bg-gray-500 text-white font-bold py-2 px-4 rounded"
                            }
                            onClick={uploadPresent}
                        >
                            Create present and get link
                        </button>
                    </div>
                }
                <div>
                    {/* If Link is set, disyplay the link not clickable with an icorn for copy next to it */}
                    {generatedLink ? (
                        <div className="flex flex-col space-y-8">
                            <div>
                                <p className="text-xl md:text-2xl font-bold">
                                    Step 4: Send your present!
                                </p>
                            </div>
                            <p>{generatedLink}</p>
                            <div
                                className="flex flex-row justify-center items-center rounded-xl bg-slate-100 hover:bg-blue-400 hover:cursor-pointer   w-2/3 mx-auto p-2"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        generatedLink
                                    );
                                }}
                            >
                                <p className="text-2xl">Copy link</p>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-10 w-10"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5h7a2 2 0 012 2v10a2 2 0 01-2 2H9a2 2 0 01-2-2V7a2 2 0 012-2zm0 0V3a2 2 0 012-2h7a2 2 0 012 2v2"
                                    />
                                </svg>
                            </div>

                            {/* Make a clipboard svg icon */}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Create;
